import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    CircularProgress,
    Alert,
    Grid,
    Card,
    CardContent,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';

const API_URL = process.env.REACT_APP_API_URL;

const timeframeOptions = [
    { value: '1m', label: '1M' },
    { value: '3m', label: '3M' },
    { value: '6m', label: '6M' },
    { value: '1y', label: '1Y' },
    { value: '3y', label: '3Y' },
    { value: '5y', label: '5Y' },
    { value: '10y', label: '10Y' },
    { value: 'all', label: 'ALL' }
];

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const value = payload[0].value;
        const date = new Date(payload[0].payload.date);
        return (
            <Paper sx={{ p: 1 }}>
                <Typography variant="body2">
                    {date.toLocaleDateString()}
                </Typography>
                <Typography variant="body2">
                    {value.toLocaleString()}
                </Typography>
            </Paper>
        );
    }
    return null;
};

const IndicatorDetail = () => {
    const { tag } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [timeframe, setTimeframe] = useState('all');
    const { token } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/dashboard/economic-indicators/categories/${tag}?timeframe=${timeframe}`
                );
                
                // Transform data for the chart
                const transformedData = response.data.series_data.map(item => ({
                    date: new Date(item.datetime),
                    value: item.value
                }));
                
                setData({
                    ...response.data,
                    chartData: transformedData
                });
                setError(null);
            } catch (err) {
                console.error('Error fetching indicator data:', err);
                setError('Failed to load indicator data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [tag, timeframe]);

    const handleTimeframeChange = (event, newTimeframe) => {
        if (newTimeframe !== null) {
            setTimeframe(newTimeframe);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    if (!data) return null;

    const latestValue = data.chartData[data.chartData.length - 1];

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {data.title}
            </Typography>
            
            <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Latest Value
                            </Typography>
                            <Typography variant="h4">
                                {latestValue.value.toLocaleString()} {data.units}
                            </Typography>
                            <Typography color="textSecondary">
                                As of {latestValue.date.toLocaleDateString()}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Details
                            </Typography>
                            <Typography>
                                Frequency: {data.frequency}
                            </Typography>
                            <Typography>
                                Units: {data.units}
                            </Typography>
                            <Typography>
                                Last Updated: {new Date(data.last_updated).toLocaleString()}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <ToggleButtonGroup
                    value={timeframe}
                    exclusive
                    onChange={handleTimeframeChange}
                    size="small"
                >
                    {timeframeOptions.map(option => (
                        <ToggleButton key={option.value} value={option.value}>
                            {option.label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>

            <Paper sx={{ p: 3, height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={data.chartData}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 20,
                            bottom: 30,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            tickFormatter={(date) => date.toLocaleDateString()}
                            angle={-45}
                            textAnchor="end"
                        />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                            dot={false}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Paper>
        </Box>
    );
};

export default IndicatorDetail; 