import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Grid,
    Button,
    useTheme,
    styled,
    Avatar,
    Link
} from '@mui/material';
import { motion } from 'framer-motion';
import Logo from '../../assets/icon.png';

const CircularImage = styled(Avatar)(({ theme }) => ({
    width: 180,
    height: 180,
    margin: '0 auto',
    border: '3px solid rgba(33, 150, 243, 0.3)',
    boxShadow: '0 4px 30px rgba(33, 150, 243, 0.2)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    }
}));

const NeonLine = styled('div')(({ theme }) => ({
    position: 'absolute',
    height: '2px',
    background: 'linear-gradient(90deg, transparent, #1976d2, #64b5f6, transparent)',
    width: '100%',
    top: '0',
    left: 0,
    animation: 'neonFlow 3s infinite linear',
    '&:after': {
        content: '""',
        position: 'absolute',
        top: '-4px',
        left: 0,
        right: 0,
        height: '10px',
        background: 'inherit',
        filter: 'blur(8px)',
        opacity: 0.5,
    },
    '@keyframes neonFlow': {
        '0%': {
            backgroundPosition: '0% 50%'
        },
        '100%': {
            backgroundPosition: '200% 50%'
        }
    }
}));

const ImageContainer = styled(motion.div)({
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    overflow: 'hidden',
    marginBottom: '16px',
    position: 'relative',
    margin: '0 auto',
    cursor: 'pointer',
    '&:hover img': {
        transform: 'scale(1.05)',
    }
});

const StyledImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.3s ease-in-out',
});

const FeatureBox = styled(motion.div)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    background: 'rgba(25, 118, 210, 0.05)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(33, 150, 243, 0.1)',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
        transform: 'translateY(-8px)',
        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
        background: 'rgba(25, 118, 210, 0.08)',
    }
}));

const LandingPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const features = [
        {
            title: 'Investment Dashboard',
            description: 'Explore the world of investing through our comprehensive data analytics platform. Get real-time notifications, track market trends, and make informed decisions with our powerful dashboard.',
            image: '/images/landing/dashboard.jpg',
            path: '/dashboard'
        },
        {
            title: 'AI Inference',
            description: 'Chat with state-of-the-art AI that learns and evolves with you. Our intelligent system understands your investment style and preferences, providing personalized insights and recommendations.',
            image: '/images/landing/servers.jpg',
            path: '/ai'
        },
        {
            title: 'Algorithmic Trading',
            description: 'Build, test, and deploy trading bots in our advanced algorithmic trading platform. Compete in our leaderboard system and perfect your strategies in a risk-free environment.',
            image: '/images/landing/algorithmic_trading.jpg',
            path: '/algo-trading'
        }
    ];

    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                pt: 2,
                pb: 6
            }}
        >
            <Container maxWidth="lg">
                {/* Hero Section with Logo */}
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Link 
                        component="div" 
                        onClick={() => navigate('/dashboard')} 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            mb: 1,
                            cursor: 'pointer',
                            textDecoration: 'none'
                        }}
                    >
                        <img 
                            src={Logo} 
                            alt="SoozHub Logo" 
                            style={{ height: '60px' }}
                        />
                        <Typography 
                            variant="h2" 
                            component="h1" 
                            sx={{ 
                                ml: 2, 
                                mb: 0,
                                color: 'text.primary'
                            }}
                        >
                            SoozHub
                        </Typography>
                    </Link>
                    <Typography
                        variant="h5"
                        sx={{
                            color: 'text.primary',
                            mb: 2,
                            maxWidth: '800px',
                            mx: 'auto',
                            mt: 1
                        }}
                    >
                        Your all-in-one platform for intelligent investing, AI-powered insights, and algorithmic trading
                    </Typography>
                </Box>

                {/* Neon Line Divider */}
                <Box sx={{ position: 'relative', height: '1px', mb: 4 }}>
                    <NeonLine />
                </Box>

                {/* Features Section */}
                <Box sx={{ position: 'relative', mb: 4 }}>
                    <Grid container spacing={3}>
                        {features.map((feature, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <FeatureBox
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: index * 0.2 }}
                                    onClick={() => navigate(feature.path)}
                                >
                                    <ImageContainer>
                                        <StyledImage
                                            src={feature.image}
                                            alt={feature.title}
                                        />
                                    </ImageContainer>
                                    <Typography variant="h5" sx={{ mt: 1.5, mb: 1, color: '#1976d2' }}>
                                        {feature.title}
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        {feature.description}
                                    </Typography>
                                </FeatureBox>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Premium Section */}
                <Box
                    sx={{
                        mt: 4,
                        p: 3,
                        borderRadius: theme.spacing(2),
                        background: 'rgba(25, 118, 210, 0.05)',
                        backdropFilter: 'blur(10px)',
                        textAlign: 'center',
                        border: '1px solid rgba(33, 150, 243, 0.1)',
                    }}
                >
                    <Typography variant="h3" gutterBottom sx={{ color: '#64b5f6', fontSize: '2.5rem' }}>
                        Get the Most Out of SoozHub
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 2, color: 'text.primary' }}>
                        Unlock premium features and take your investment journey to the next level
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate('/pricing')}
                        sx={{
                            background: 'linear-gradient(45deg, #1976d2 30%, #64b5f6 90%)',
                            color: 'white',
                            px: 4,
                            py: 1.5,
                            fontSize: '1.1rem',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #1565c0 30%, #42a5f5 90%)',
                            }
                        }}
                    >
                        See Pricing Options
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default LandingPage; 