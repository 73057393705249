import React, { useState, useEffect } from 'react';
import { Grid, Typography, Link, useMediaQuery, useTheme, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AppBar from './components/AppBar';
import MainRoutes from './routes/MainRoutes';
import LeftNavbar from './components/LeftNavbar';
import { dashboardNavbarOptions, aiNavbarOptions } from './config/navbarOptions';
import PageViewTracker from './components/PageViewTracker';

function AppContent() {
    const currentTheme = useTheme();
    const isMobile = useMediaQuery(currentTheme.breakpoints.down('sm'));
    const [navbarOptions, setNavbarOptions] = useState(dashboardNavbarOptions);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/dashboard' || location.pathname.startsWith('/sentiment')) {
            setNavbarOptions(dashboardNavbarOptions);
        } else if (location.pathname.startsWith('/ai')) {
            setNavbarOptions(aiNavbarOptions);
        }
    }, [location.pathname]);

    // Hide navigation elements on login, about, contact, pricing, and landing pages
    const isFullScreenPage = ['/login', '/about', '/contact', '/pricing', '/landing'].includes(location.pathname);

    if (isFullScreenPage) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <MainRoutes setNavbarOptions={setNavbarOptions} />
                <PageViewTracker />
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AppBar />
            <Box component="main" sx={{ flexGrow: 1, display: 'flex' }}>
                <Grid container sx={{ flexGrow: 1 }}>
                    {!isMobile && (
                        <Grid item xs={2} sx={{ 
                            borderRight: '1px solid',
                            borderColor: 'divider',
                            height: '100%',
                            overflow: 'auto'
                        }}>
                            <LeftNavbar options={navbarOptions} />
                        </Grid>
                    )}
                    <Grid item xs={isMobile ? 12 : location.pathname.startsWith('/ai') ? 10 : 8} sx={{ height: '100%', overflow: 'auto' }}>
                        <MainRoutes setNavbarOptions={setNavbarOptions} />
                        <PageViewTracker />
                    </Grid>
                    {!isMobile && !location.pathname.startsWith('/ai') && (
                        <Grid item xs={2} sx={{ 
                            borderLeft: '1px solid',
                            borderColor: 'divider',
                            height: '100%',
                            overflow: 'auto'
                        }}>
                            {/* Right Navbar content (empty for now) */}
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box component="footer" sx={{ 
                bgcolor: 'background.footer', 
                color: "white", 
                py: 1,
                borderTop: '1px solid',
                borderColor: 'divider'
            }}>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    <Link href="/contact" color="inherit" sx={{ px: 1 }}>Contact</Link>
                    <Link href="/about" color="inherit" sx={{ px: 1 }}>About</Link>
                </Typography>
            </Box>
        </Box>
    );
}

export default AppContent;