import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';
import HalvingCycleChart from './HalvingCycleChart';

const API_URL = process.env.REACT_APP_API_URL;

const HalvingCycle = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { token } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/dashboard/btc-halving`,
                    token ? { headers: { Authorization: `Bearer ${token}` } } : {}
                );
                console.log('Halving data response:', response.data);
                if (!response.data || response.data.length === 0) {
                    setError('No halving data available');
                    return;
                }
                setData(response.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching halving data:', err);
                setError('Failed to load BTC halving data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Bitcoin Halving Cycle Analysis
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
                Analysis of BTC price performance after each halving event, divided into bull market (first 2 years) and bear market (years 3-4) periods.
            </Typography>
            
            <Box sx={{ mb: 6 }}>
                <Typography variant="h5" gutterBottom>
                    Bull Market Returns (First 2 Years After Halving)
                </Typography>
                <HalvingCycleChart data={data} type="bull" />
            </Box>

            <Box sx={{ mb: 6 }}>
                <Typography variant="h5" gutterBottom>
                    Bear Market Returns (Years 3-4 After Halving)
                </Typography>
                <HalvingCycleChart data={data} type="bear" />
            </Box>
        </Box>
    );
};

export default HalvingCycle; 