import { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const useRarityPriceData = (contractAddress) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!contractAddress) return;
            
            setLoading(true);
            setError(null);
            
            try {
                const response = await axios.get(
                    `${API_URL}/nft/rarity-price/${contractAddress}`
                );
                
                // Transform data for scatter plot
                const plotData = response.data.listings
                    .filter(listing => listing.openrarity_rank !== null)
                    .map(listing => ({
                        identifier: listing.identifier,
                        rarity: listing.openrarity_rank,
                        price: listing.price / 1e18, // Convert from wei to ETH
                        endTime: listing.end_time,
                    }));

                // Get regression coefficients
                const { slope, intercept } = response.data.regression;

                console.log('Regression data:', { slope, intercept });

                // Calculate regression line points
                const rarityValues = plotData.map(item => item.rarity);
                const minRarity = Math.min(...rarityValues);
                const maxRarity = Math.max(...rarityValues);
                
                // Create more points for a smoother line
                const numPoints = 50;
                const step = (maxRarity - minRarity) / (numPoints - 1);
                const regressionLine = Array.from({ length: numPoints }, (_, i) => {
                    const rarity = minRarity + (step * i);
                    return {
                        rarity,
                        price: slope * rarity + intercept
                    };
                });

                console.log('First and last regression points:', {
                    first: regressionLine[0],
                    last: regressionLine[regressionLine.length - 1]
                });

                setData({
                    contractAddress: response.data.contract_address,
                    collectionName: response.data.collection_name,
                    listings: plotData,
                    regressionLine,
                    regression: { slope, intercept }
                });
            } catch (err) {
                console.error('Error fetching NFT rarity-price data:', err);
                setError(err.response?.data?.detail || 'Failed to fetch NFT rarity-price data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [contractAddress]);

    return { data, loading, error };
}; 