import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);
const API_URL = process.env.REACT_APP_API_URL;

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh_token'));
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const refreshAccessToken = async () => {
        try {
            const response = await axios.post(`${API_URL}/auth/token/refresh`, {
                refresh_token: refreshToken
            });
            const { access_token } = response.data;
            setToken(access_token);
            localStorage.setItem('token', access_token);
            return access_token;
        } catch (error) {
            console.error('Error refreshing token:', error);
            logout();
            return null;
        }
    };

    const login = async (username, password) => {
        try {
            // Try to login with username or email
            const formData = new URLSearchParams();
            formData.append('username', username);  // FastAPI expects 'username' field
            formData.append('password', password);

            const response = await axios.post(`${API_URL}/auth/token`, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            const { access_token, refresh_token, user } = response.data;
            setToken(access_token);
            setRefreshToken(refresh_token);
            setUser(user);
            localStorage.setItem('token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            return { success: true };
        } catch (error) {
            console.error('Login error:', error);
            return { 
                success: false, 
                error: error.response?.data?.detail || 'Failed to login' 
            };
        }
    };

    const logout = () => {
        setToken(null);
        setRefreshToken(null);
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        navigate('/login');
    };

    const fetchUserData = async (currentToken) => {
        try {
            const response = await axios.get(`${API_URL}/auth/users/me`, {
                headers: { Authorization: `Bearer ${currentToken}` }
            });
            setUser(response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching user data:', error);
            if (error.response?.status === 401) {
                // Token expired or invalid
                logout();
            }
            return null;
        }
    };

    // Check token and fetch user data on mount or token change
    useEffect(() => {
        const initAuth = async () => {
            if (token) {
                try {
                    await fetchUserData(token);
                } catch (error) {
                    console.error('Error initializing auth:', error);
                    logout();
                }
            }
            setLoading(false);
        };

        initAuth();
    }, [token]);

    // Intercept 401 responses and try to refresh token
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;
                if (error.response?.status === 401 && !originalRequest._retry && refreshToken) {
                    originalRequest._retry = true;
                    const newToken = await refreshAccessToken();
                    if (newToken) {
                        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                        return axios(originalRequest);
                    }
                }
                if (error.response?.status === 401) {
                    logout();
                }
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, [refreshToken]);

    const isAuthenticated = () => {
        return !!token && !!user;
    };

    const value = {
        token,
        user,
        login,
        logout,
        isAuthenticated,
        loading,
        refreshAccessToken
    };

    if (loading) {
        return <div>Loading...</div>; // Or your loading component
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
