import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, ToggleButtonGroup, ToggleButton} from '@mui/material';

const ControlPanel = ({ 
    symbols = [], 
    selectedSymbol, 
    setSelectedSymbol, 
    currentFrame, 
    setCurrentFrame, 
    movingAverageDays, 
    setMovingAverageDays, 
    isMobile 
}) => {
    const symbolsList = Array.isArray(symbols) ? symbols : [];

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row',
            gap: 1.5, 
            mb: 3, 
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center'
        }}>
            <Box sx={{ 
                display: 'flex', 
                gap: 1.5, 
                mb: isMobile ? 1.5 : 0,
                width: isMobile ? '100%' : 'auto',
                justifyContent: 'center'
            }}>
                <FormControl size="small" sx={{ width: isMobile ? 90 : 80 }}>
                    <InputLabel id="symbol-select-label">Symbol</InputLabel>
                    <Select
                        labelId="symbol-select-label"
                        id="symbolSelect"
                        value={selectedSymbol}
                        label="Symbol"
                        onChange={(e) => setSelectedSymbol(e.target.value)}
                    >
                        {symbolsList.map(symbol => (
                            <MenuItem key={symbol} value={symbol}>{symbol}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl size="small" sx={{ width: isMobile ? 90 : 80 }}>
                    <InputLabel id="ma-select-label">MA</InputLabel>
                    <Select
                        labelId="ma-select-label"
                        id="maSelect"
                        value={movingAverageDays}
                        label="MA"
                        onChange={(e) => setMovingAverageDays(e.target.value === 'null' ? null : parseInt(e.target.value, 10))}
                    >
                        <MenuItem value={null}>None</MenuItem>
                        <MenuItem value={3}>3 days</MenuItem>
                        <MenuItem value={7}>7 days</MenuItem>
                        <MenuItem value={30}>30 days</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <ToggleButtonGroup
                value={currentFrame}
                exclusive
                onChange={(event, newFrame) => {
                    if (newFrame !== null) {
                        setCurrentFrame(newFrame);
                    }
                }}
                size="small"
                sx={{ 
                    flexWrap: 'wrap', 
                    justifyContent: 'center',
                    width: isMobile ? '100%' : 'auto'
                }}
            >
                <ToggleButton value="7D">7D</ToggleButton>
                <ToggleButton value="1M">1M</ToggleButton>
                <ToggleButton value="3M">3M</ToggleButton>
                <ToggleButton value="All">All</ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
};

export default ControlPanel;