import React from 'react';
import Highcharts from 'highcharts';
import { Box } from '@mui/material';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

HighchartsAccessibility(Highcharts);
const SentimentGraph = ({ data, movingAverageDays, isMobile }) => {
    React.useEffect(() => {
        if (data && data.length > 0) {
            renderGraph(data, movingAverageDays);
        }
    }, [data, movingAverageDays]);

    const renderGraph = (data) => {
        const maxComments = Math.max(...data.map(item => item.total_comments));
        const yAxisMax = maxComments * 3;
        let barWidth = data.length > 50 ? 5 : 10;  // Adjust this condition based on your typical dataset sizes

        const options = {
            chart: {
                type: 'spline',
                zoomType: 'xy',
                backgroundColor: 'transparent'
            },
            title: {
                text: ''
            },
            accessibility: {
                enabled: true,
                description: 'Chart showing sentiment analysis over time, including total comments and daily average sentiment',
                keyboardNavigation: {
                    enabled: true
                },
                announceNewData: {
                    enabled: true,
                    announcementFormatter: function (allSeries, newSeries, newPoint) {
                        if (newPoint) {
                            return 'New point added. Date: ' + Highcharts.dateFormat('%Y-%m-%d', newPoint.x) + ', Value: ' + newPoint.y;
                        }
                        return false;
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { day: '%m-%d' },
                labels: {
                    rotation: -45,
                    style: {
                        color: '#d9d9d9'
                    }
                }
            },
            yAxis: [{
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                },
                opposite: true,
                min: 0,
                max: yAxisMax
            }, {
                title: {
                    text: 'Sentiment Average',
                    style: {
                        color: '#d9d9d9'
                    }
                },
                labels: {
                    format: '{value}',
                    style: {
                        color: '#d9d9d9'
                    }
                },
                opposite: false,
                plotLines: [{
                    value: 0,
                    color: 'white',
                    width: 3,
                    zIndex: 1
                }]
            }],
            tooltip: {
                shared: true,
                xDateFormat: '%Y-%m-%d',
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                style: {
                    color: '#d9d9d9'
                },
                borderColor: '#6c757d',
                borderRadius: 10,
                borderWidth: 1
            },
            plotOptions: {
                series: {
                    requireSortData: false
                },
                column: {
                    pointPadding: isMobile ? 0.05 : 0.1,
                    borderWidth: 0,
                    groupPadding: isMobile ? 0.05 : 0.1,
                    shadow: false,
                    pointWidth: barWidth
                }
            },
            series: [{
                name: 'Total Comments',
                type: 'column',
                data: data.map(item => [new Date(item.datetime).getTime(), item.total_comments]),
                color: isMobile ? 'rgba(211, 211, 211, 0.5)' : 'lightgray', // More transparent on mobile
                yAxis: 0,
            }, {
                name: 'Daily Average',
                type: 'spline',
                data: data.map(item => [new Date(item.datetime).getTime(), item.average]),
                yAxis: 1,
                zones: [{ value: 0, color: 'red' }, { value: Infinity, color: 'green' }]
            }]
        };

        if (movingAverageDays) {
            options.series.push(calculateMovingAverage(data, movingAverageDays));
        }

        Highcharts.chart('sentimentGraph', options);
    };

    const calculateMovingAverage = (data, windowSize) => {
        let movingAverages = [];
        let movingAverageDates = [];

        for (let i = data.length - windowSize; i >= 0; i--) {
            let sum = 0;
            for (let j = 0; j < windowSize; j++) {
                sum += data[i + j].average;
            }
            movingAverages.push(sum / windowSize);
            movingAverageDates.push(new Date(data[i].datetime).getTime());
        }

        return {
            name: `${windowSize}-Day Moving Average`,
            type: 'spline',
            data: movingAverages.map((avg, index) => [movingAverageDates[index], avg]),
            yAxis: 1,
            color: 'blue',
            zIndex: 1,
            marker: { enabled: false }
        };
    };

    return (
        <Box 
            id="sentimentGraph" 
            sx={{ 
                width: '100%', 
                height: isMobile ? 400 : 500 
            }} 
        />
    );
};

export default SentimentGraph;