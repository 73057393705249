import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ProfilePage from '../components/ProfilePage';
import LoginPage from '../components/LoginPage';
import AboutPage from '../components/About';
import ContactPage from '../components/Contact';
import RedditSentiment from '../components/graphs/RedditSentiment/RedditSentiment';
import AlgoTradingBotsPage from '../components/AlgoTrading/AlgoTradingBotsPage';
import AIPage from '../components/AI/AIPage';
import NFTPage from '../components/NFT/NFTPage';
import EconomicIndicators from '../pages/EconomicIndicators';
import IndicatorDetail from '../pages/EconomicIndicators/IndicatorDetail';
import LandingPage from '../pages/LandingPage';
import PricingPage from '../pages/PricingPage';
import HalvingCycle from '../components/graphs/HalvingCycle';

const API_URL = process.env.REACT_APP_API_URL;

const MainRoutes = ({ setNavbarOptions }) => {
    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<LoginPage apiUrl={API_URL} />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/pricing" element={<PricingPage />} />

            {/* Public Dashboard Routes */}
            <Route path="/dashboard" element={<RedditSentiment apiUrl={API_URL} setNavbarOptions={setNavbarOptions} />} />
            <Route path="/halving-returns" element={<HalvingCycle />} />
            <Route path="/nfts" element={<NFTPage apiUrl={API_URL} setNavbarOptions={setNavbarOptions} />} />
            <Route path="/economic-indicators" element={<EconomicIndicators apiUrl={API_URL} setNavbarOptions={setNavbarOptions} />} />
            <Route path="/economic-indicators/:tag" element={<IndicatorDetail apiUrl={API_URL} setNavbarOptions={setNavbarOptions} />} />

            {/* Private Routes - Require Authentication */}
            <Route path="/profile" element={<PrivateRoute><ProfilePage apiUrl={API_URL} setNavbarOptions={setNavbarOptions} /></PrivateRoute>} />
            <Route path="/algo-trading-bots/*" element={<PrivateRoute><AlgoTradingBotsPage apiUrl={API_URL} setNavbarOptions={setNavbarOptions} /></PrivateRoute>} />
            <Route path="/ai/*" element={<PrivateRoute><AIPage apiUrl={API_URL} setNavbarOptions={setNavbarOptions} /></PrivateRoute>} />
        </Routes>
    );
};

export default MainRoutes;