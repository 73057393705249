import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography, Box, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useAuth } from '../AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
    borderRadius: 0,
    marginBottom: theme.spacing(0.5),
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
        '& .action-buttons': {
            opacity: 1,
        },
    },
    ...(selected && {
        backgroundColor: 'rgba(144, 202, 249, 0.16)',
        borderLeft: '4px solid #90caf9',
        paddingLeft: theme.spacing(2.75),
        '& .MuiTypography-root': {
            color: '#90caf9',
            fontWeight: 600,
        },
        '& .MuiListItemIcon-root': {
            color: '#90caf9',
        },
        '&:hover': {
            backgroundColor: 'rgba(144, 202, 249, 0.24)',
        },
    }),
}));

const ActionButtons = styled(Box)({
    position: 'absolute',
    right: 8,
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
});

const SectionTitle = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
}));

const LeftNavbar = ({ options }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { token } = useAuth();
    const [editingId, setEditingId] = useState(null);
    const [editTitle, setEditTitle] = useState('');

    const handleItemClick = (item) => {
        if (item.onClick) {
            item.onClick();
        } else if (item.path) {
            navigate(item.path);
        }
    };

    const handleEdit = async (item, newTitle) => {
        try {
            await axios.put(
                `${API_URL}/ai/conversations/${item.id}/title`,
                { title: newTitle },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setEditingId(null);
            // Refresh the conversations list
            if (options.onUpdate) {
                options.onUpdate();
            }
        } catch (error) {
            console.error('Error updating conversation title:', error);
        }
    };

    const handleDelete = async (item) => {
        try {
            await axios.delete(
                `${API_URL}/ai/conversations/${item.id}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            // Refresh the conversations list
            if (options.onUpdate) {
                options.onUpdate();
            }
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };

    const handleEditClick = (e, item) => {
        e.stopPropagation();
        setEditingId(item.id);
        setEditTitle(item.label);
    };

    const handleDeleteClick = (e, item) => {
        e.stopPropagation();
        handleDelete(item);
    };

    const handleKeyPress = (e, item) => {
        if (e.key === 'Enter') {
            handleEdit(item, editTitle);
        } else if (e.key === 'Escape') {
            setEditingId(null);
        }
    };

    return (
        <Box sx={{ 
            height: '100%', 
            bgcolor: 'background.paper', 
            borderRight: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Typography variant="h6" sx={{ p: 2, borderBottom: '1px solid', borderColor: 'divider', flexShrink: 0 }}>
                {options.title || 'Navigation'}
            </Typography>
            <List 
                component="nav" 
                sx={{ 
                    width: '100%', 
                    pt: 0,
                    overflowY: 'auto',
                    flexGrow: 1,
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: 'rgba(255, 255, 255, 0.2)',
                    },
                }}
            >
                {options.sections?.map((section, index) => (
                    <React.Fragment key={section.title}>
                        <SectionTitle>
                            {section.title}
                        </SectionTitle>
                        {section.items.map((item) => (
                            <StyledListItem
                                key={item.id}
                                selected={item.selected || location.pathname === item.path}
                                onClick={() => handleItemClick(item)}
                                sx={{ 
                                    pl: 3,
                                    ...item.style,
                                    '& .MuiListItemSecondaryAction-root': {
                                        right: 8
                                    }
                                }}
                                button
                            >
                                {item.icon && (
                                    <ListItemIcon sx={{ minWidth: 40, color: 'inherit' }}>
                                        {item.icon}
                                    </ListItemIcon>
                                )}
                                {editingId === item.id ? (
                                    <input
                                        type="text"
                                        value={editTitle}
                                        onChange={(e) => setEditTitle(e.target.value)}
                                        onKeyDown={(e) => handleKeyPress(e, item)}
                                        onBlur={() => handleEdit(item, editTitle)}
                                        autoFocus
                                        onClick={(e) => e.stopPropagation()}
                                        className="bg-gray-800 text-white px-2 py-1 rounded border border-gray-600 focus:outline-none focus:border-blue-500 w-full"
                                    />
                                ) : (
                                    <>
                                        <ListItemText 
                                            primary={item.label}
                                            primaryTypographyProps={{
                                                fontSize: '0.9rem',
                                                noWrap: true
                                            }}
                                        />
                                        {section.title === 'History' && (
                                            <ActionButtons className="action-buttons">
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => handleEditClick(e, item)}
                                                    sx={{ 
                                                        color: 'text.secondary',
                                                        padding: '4px',
                                                        '&:hover': { color: 'primary.main' }
                                                    }}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => handleDeleteClick(e, item)}
                                                    sx={{ 
                                                        color: 'text.secondary',
                                                        padding: '4px',
                                                        '&:hover': { color: 'error.main' }
                                                    }}
                                                >
                                                    <DeleteOutlineIcon fontSize="small" />
                                                </IconButton>
                                            </ActionButtons>
                                        )}
                                    </>
                                )}
                            </StyledListItem>
                        ))}
                        {index < options.sections.length - 1 && (
                            <Box sx={{ my: 1, borderBottom: '1px solid', borderColor: 'divider', opacity: 0.2 }} />
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
};

export default LeftNavbar;