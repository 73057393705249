import { useState, useEffect } from 'react';

const DEFAULT_SYMBOLS = ['BTC', 'ETH', 'SOL', 'DOGE', 'BNB', 'SHIB', 'MATIC', 'GALA',
    'XMR', 'ADA', 'LTC', 'AVAX', 'APE', 'FIL', 'MANA', 'DOT', 'RVN',
    'MOON', 'GRC', 'FOX', 'ZEC', 'GRT', 'XRP', 'APT', 'SAND', 'FET',
    'FTM', 'OP', 'LINK', 'ATOM', 'ALGO', 'AXS', 'UNI', 'NEO', 'ICP',
    'EGLD', 'HBAR', 'XTZ', 'PEPE', 'TON', 'LDO', 'CRO', 'ARB', 'VET',
    'EOS', 'CAKE', 'SUSHI', 'RUNE'];

const useSentimentData = (apiUrl, currentFrame, selectedSymbol, selectedSubreddit, movingAverageDays) => {
    const [symbols, setSymbols] = useState(DEFAULT_SYMBOLS);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchSymbols() {
            try {
                const symbolsRes = await fetch(`${apiUrl}/dashboard/reddit_sentiment/symbols/`);
                if (!symbolsRes.ok) {
                    throw new Error('Failed to fetch symbols');
                }
                const symbolsData = await symbolsRes.json();
                if (Array.isArray(symbolsData) && symbolsData.length > 0) {
                    setSymbols(symbolsData);
                }
            } catch (error) {
                console.error('Error fetching symbols:', error);
                setError(error);
                // Keep using DEFAULT_SYMBOLS if fetch fails
            }
        }

        fetchSymbols();
    }, [apiUrl]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            // Convert timeframe to lowercase and map to backend format
            const timeframe = currentFrame.toLowerCase() === 'all' ? 'all' : 
                            currentFrame.toLowerCase().replace('d', 'd').replace('m', 'm');
            
            const fullApiUrl = `${apiUrl}/dashboard/reddit_sentiment/?timeframe=${timeframe}&symbol=${selectedSymbol}&subreddit=${selectedSubreddit}`;
            try {
                const response = await fetch(fullApiUrl);
                if (!response.ok) {
                    throw new Error('Failed to fetch sentiment data');
                }
                const data = await response.json();
                setData(data);
                setError(null);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setError(error);
                setData([]);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [apiUrl, currentFrame, selectedSymbol, selectedSubreddit, movingAverageDays]);

    return { symbols, data, loading, error };
};

export default useSentimentData;