import React, { useState, useMemo } from 'react';
import { Box, Typography, Paper, CircularProgress, Alert, TextField, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';
import { useRarityPriceData } from './useRarityPriceData';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <Paper sx={{ p: 1 }}>
                <Typography variant="body2">ID: #{data.identifier}</Typography>
                <Typography variant="body2">
                    Rarity Rank: {data.rarity ? data.rarity.toFixed(2) : 'N/A'}
                </Typography>
                <Typography variant="body2">Price: {data.price.toFixed(4)} ETH</Typography>
                {data.endTime && (
                    <Typography variant="body2">
                        Ends: {new Date(data.endTime).toLocaleString()}
                    </Typography>
                )}
            </Paper>
        );
    }
    return null;
};

const RarityPriceChart = ({ contractAddress }) => {
    const { data, loading, error } = useRarityPriceData(contractAddress);
    const [maxPrice, setMaxPrice] = useState(''); // Will be set after data loads

    const handleMaxPriceChange = (event) => {
        const value = event.target.value;
        // Allow empty string or valid numbers
        if (value === '' || (!isNaN(value) && Number(value) >= 0)) {
            setMaxPrice(value);
        }
    };

    // Set initial max price when data loads
    React.useEffect(() => {
        if (data?.regression?.intercept) {
            const defaultMax = Math.ceil(data.regression.intercept * 3);
            setMaxPrice(defaultMax.toString());
        }
    }, [data]);

    const filteredData = useMemo(() => {
        if (!data) return null;

        const maxPriceNum = maxPrice === '' ? Infinity : Number(maxPrice);
        const filteredListings = data.listings.filter(point => point.price <= maxPriceNum);

        return {
            ...data,
            listings: filteredListings
        };
    }, [data, maxPrice]);

    if (loading) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}><CircularProgress /></Box>;
    if (error) return <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>;
    if (!filteredData) return null;

    return (
        <Box sx={{ height: 500, mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6">
                        Rarity vs Price Analysis
                    </Typography>
                    <Tooltip title="The trend line shows the price-rarity relationship based on sales from the last 30 days">
                        <IconButton size="small">
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ width: 200 }}>
                    <TextField
                        label="Max Price (ETH)"
                        type="text"
                        value={maxPrice}
                        onChange={handleMaxPriceChange}
                        size="small"
                        fullWidth
                        InputProps={{
                            inputProps: { 
                                step: "any",
                                min: 0
                            }
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <ScatterChart
                        margin={{
                            top: 20,
                            right: 30,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                            type="number" 
                            dataKey="rarity" 
                            name="Rarity Rank"
                            label={{ value: 'Rarity Rank', position: 'bottom' }}
                        />
                        <YAxis 
                            type="number" 
                            dataKey="price" 
                            name="Price"
                            label={{ value: 'Price (ETH)', angle: -90, position: 'left' }}
                            tickFormatter={(value) => value.toFixed(4)}
                        />
                        <RechartsTooltip content={<CustomTooltip />} />
                        <Scatter
                            name="Regression"
                            data={filteredData.regressionLine}
                            line={{ stroke: '#ff7300', strokeWidth: 2 }}
                            dot={false}
                            legendType="none"
                        />
                        <Scatter 
                            name="NFTs" 
                            data={filteredData.listings} 
                            fill="#8884d8"
                        />
                    </ScatterChart>
                </ResponsiveContainer>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
                <Typography variant="body2" color="textSecondary">
                    Showing {filteredData.listings.length} of {data.listings.length} listings
                </Typography>
            </Box>
        </Box>
    );
};

export default RarityPriceChart; 