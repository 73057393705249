import React, { useState } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Paper, CircularProgress, Alert } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useNFTData } from './useNFTData';
import RarityPriceChart from './RarityPriceChart';

const timeRanges = [
    { value: 7, label: '7 Days' },
    { value: 30, label: '30 Days' },
    { value: 90, label: '90 Days' },
    { value: 180, label: '180 Days' },
    { value: 365, label: '1 Year' }
];

const NFTDashboard = () => {
    const [selectedCollection, setSelectedCollection] = useState('overall');
    const [selectedDays, setSelectedDays] = useState(30);
    const { data, collections, loading, error } = useNFTData(selectedCollection, selectedDays);

    const handleCollectionChange = (event) => {
        setSelectedCollection(event.target.value);
    };

    const handleTimeRangeChange = (event) => {
        setSelectedDays(event.target.value);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ mb: 2 }}>
                {error}
            </Alert>
        );
    }

    return (
        <Paper sx={{ p: 3, height: '100%' }}>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                    NFT Price Tracker
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                        <InputLabel>Collection</InputLabel>
                        <Select
                            value={selectedCollection}
                            label="Collection"
                            onChange={handleCollectionChange}
                        >
                            <MenuItem value="overall">Overall Market</MenuItem>
                            {collections.map((collection) => (
                                <MenuItem 
                                    key={collection.contract_address} 
                                    value={collection.contract_address}
                                >
                                    {collection.collection_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl size="small" sx={{ minWidth: 120 }}>
                        <InputLabel>Time Range</InputLabel>
                        <Select
                            value={selectedDays}
                            label="Time Range"
                            onChange={handleTimeRangeChange}
                        >
                            {timeRanges.map((range) => (
                                <MenuItem key={range.value} value={range.value}>
                                    {range.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            {data && (
                <>
                    <Box sx={{ height: 400 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                data={data.prices}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="date"
                                    tick={{ fontSize: 12 }}
                                    interval="preserveStartEnd"
                                />
                                <YAxis 
                                    tick={{ fontSize: 12 }}
                                    domain={['auto', 'auto']}
                                    tickFormatter={(value) => `${value.toFixed(4)} ETH`}
                                />
                                <Tooltip 
                                    formatter={(value) => [`${value.toFixed(4)} ETH`, 'Price']}
                                    labelFormatter={(label) => `Date: ${label}`}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="price"
                                    stroke="#8884d8"
                                    strokeWidth={2}
                                    dot={false}
                                    activeDot={{ r: 8 }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>

                    {selectedCollection !== 'overall' && (
                        <RarityPriceChart contractAddress={selectedCollection} />
                    )}
                </>
            )}
        </Paper>
    );
};

export default NFTDashboard; 