import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const COLORS = ['#4CAF50', '#2196F3', '#FF9800', '#9C27B0'];

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Box sx={{ 
                bgcolor: 'background.paper', 
                p: 1.5, 
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1
            }}>
                <Typography variant="body2">
                    Days after {label < 0 ? "bear market start" : "halving"}: {Math.abs(label)}
                </Typography>
                {payload.map((entry, index) => (
                    <Typography 
                        key={index} 
                        variant="body2" 
                        sx={{ color: entry.color }}
                    >
                        {entry.name}: {entry.value.toFixed(2)}x
                    </Typography>
                ))}
            </Box>
        );
    }
    return null;
};

const HalvingCycleChart = ({ data, type }) => {
    const theme = useTheme();
    
    if (!data || !Array.isArray(data) || data.length === 0) {
        console.error('Invalid data provided to HalvingCycleChart:', data);
        return (
            <Box sx={{ width: '100%', p: 2 }}>
                <Typography color="error">No data available</Typography>
            </Box>
        );
    }

    // Create a map of all days and their corresponding returns for each halving
    const daysMap = new Map();
    data.forEach((halvingData, index) => {
        const returns = type === 'bull' ? halvingData.bull_returns : halvingData.bear_returns;
        if (returns && returns.length > 0) {
            // For bear market, find the first price to use as reference
            let referencePrice = type === 'bull' ? 
                returns[0].return_multiple * (1 / returns[0].return_multiple) : // Normalize to 1
                returns[0].return_multiple * (1 / returns[0].return_multiple);  // Normalize to 1

            returns.forEach(point => {
                const adjustedDays = type === 'bull' ? 
                    point.days_after_halving : 
                    point.days_after_halving - 730; // Subtract 730 days for bear market to start at day 0

                if (!daysMap.has(adjustedDays)) {
                    daysMap.set(adjustedDays, {
                        days_after_halving: adjustedDays
                    });
                }
                const date = new Date(halvingData.halving_date);
                const year = date.getFullYear();
                const normalizedReturn = type === 'bull' ?
                    point.return_multiple :
                    point.return_multiple / returns[0].return_multiple; // Normalize to the start of bear market
                daysMap.get(adjustedDays)[`${year}`] = normalizedReturn;
            });
        }
    });

    // Convert map to array and sort by days
    const chartData = Array.from(daysMap.values())
        .sort((a, b) => a.days_after_halving - b.days_after_halving);

    if (chartData.length === 0) {
        return (
            <Box sx={{ width: '100%', p: 2 }}>
                <Typography>
                    No data available for this period
                </Typography>
            </Box>
        );
    }

    // Get unique years for creating lines
    const years = data
        .map(halvingData => new Date(halvingData.halving_date).getFullYear())
        .sort();

    return (
        <Box sx={{ width: '100%', height: 400, mb: 4 }}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                        dataKey="days_after_halving"
                        label={{ 
                            value: type === 'bull' ? 'Days After Halving' : 'Days After Bear Market Start',
                            position: 'insideBottom',
                            offset: -5
                        }}
                    />
                    <YAxis 
                        label={{ 
                            value: 'Return Multiple (x)',
                            angle: -90,
                            position: 'insideLeft'
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    {years.map((year, index) => (
                        <Line
                            key={year}
                            type="monotone"
                            dataKey={year.toString()}
                            name={`${year} Halving`}
                            stroke={COLORS[index % COLORS.length]}
                            dot={false}
                            strokeWidth={2}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default HalvingCycleChart; 