import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import AppContent from './AppContent';
import darkTheme from './themes/darkTheme';

function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <Router>
                <AuthProvider>
                    <CssBaseline />
                    <AppContent />
                </AuthProvider>
            </Router>
        </ThemeProvider>
    );
}

export default App;