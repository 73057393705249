import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import DatasetIcon from '@mui/icons-material/Storage';
import Datasets from './Datasets';

const AlgoTradingBotsPage = ({ setNavbarOptions }) => {
    const [selectedOption, setSelectedOption] = useState('datasets');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setNavbarOptions({
            title: 'Algo Trading',
            sections: [
                {
                    title: 'Data',
                    items: [
                        { 
                            id: 'datasets', 
                            label: 'Datasets', 
                            icon: <DatasetIcon />, 
                            path: '/algo-trading-bots/datasets',
                            selected: selectedOption === 'datasets'
                        },
                    ]
                }
            ]
        });
    }, [setNavbarOptions, selectedOption]);

    const renderContent = () => {
        switch(selectedOption) {
            case 'datasets':
                return <Datasets />;
            default:
                return <Datasets />;
        }
    };

    return (
        <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} sx={{ height: '100%', overflow: 'auto' }}>
                {renderContent()}
            </Grid>
        </Grid>
    );
};

export default AlgoTradingBotsPage;