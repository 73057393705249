import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const LoginPage = () => {
    const [mode, setMode] = useState('login');
    const [userData, setUserData] = useState({
        email: '',
        username: '',
        password: '',
        password_confirm: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            if (mode === 'login') {
                const loginIdentifier = userData.username || userData.email;
                const result = await login(loginIdentifier, userData.password);
                if (result.success) {
                    navigate('/');
                } else {
                    setError(result.error);
                }
            } else {
                if (!userData.email || !userData.username || !userData.password) {
                    setError('All fields are required');
                    setLoading(false);
                    return;
                }
                if (userData.password !== userData.password_confirm) {
                    setError('Passwords do not match');
                    setLoading(false);
                    return;
                }
                if (userData.password.length < 6) {
                    setError('Password must be at least 6 characters long');
                    setLoading(false);
                    return;
                }

                const response = await axios.post(`${API_URL}/auth/register`, {
                    email: userData.email,
                    username: userData.username,
                    password: userData.password
                });

                if (response.data) {
                    const loginResult = await login(userData.username, userData.password);
                    if (loginResult.success) {
                        navigate('/');
                    } else {
                        setError('Registration successful but login failed');
                    }
                }
            }
        } catch (err) {
            console.error('Auth error:', err);
            if (err.response?.data?.detail) {
                setError(err.response.data.detail);
            } else if (err.response?.data?.validation) {
                setError(Object.values(err.response.data.validation)[0]);
            } else {
                setError('An unexpected error occurred');
            }
        } finally {
            setLoading(false);
        }
    };

    const toggleMode = () => {
        setMode(prev => prev === 'login' ? 'register' : 'login');
        setError('');
        setUserData({
            email: '',
            username: '',
            password: '',
            password_confirm: ''
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    {mode === 'login' ? 'Sign in' : 'Register'}
                </Typography>
                <Button
                    startIcon={<GoogleIcon />}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => console.log(`${mode === 'login' ? 'Login' : 'Sign up'} with Google`)}
                >
                    {mode === 'login' ? 'Sign in with Google' : 'Sign up with Google'}
                </Button>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={userData.email}
                        onChange={handleChange}
                        disabled={loading}
                    />
                    {mode === 'register' && (
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            value={userData.username}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    )}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete={mode === 'login' ? 'current-password' : 'new-password'}
                        value={userData.password}
                        onChange={handleChange}
                        disabled={loading}
                    />
                    {mode === 'register' && (
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password_confirm"
                            label="Confirm Password"
                            type="password"
                            id="password_confirm"
                            autoComplete="new-password"
                            value={userData.password_confirm}
                            onChange={handleChange}
                            disabled={loading}
                        />
                    )}
                    {error && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {typeof error === 'string' ? error : 'Invalid username or password'}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : (mode === 'login' ? 'Sign In' : 'Register')}
                    </Button>
                    <Button
                        fullWidth
                        sx={{
                            mt: 2,
                            color: '#c5cae9',
                            typography: 'body2',
                            p: 0.5,
                            textTransform: 'none',
                            border: '1px solid transparent',
                            '&:hover': {
                                borderColor: '#7986cb',
                                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                color: '#fff'
                            }
                        }}
                        onClick={toggleMode}
                    >
                        {mode === 'login' ? 'Need an account? Register' : 'Already have an account? Login'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
