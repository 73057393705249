import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { NFTDashboard } from '../graphs/NFT';
import { dashboardNavbarOptions } from '../../config/navbarOptions';

const NFTPage = ({ setNavbarOptions }) => {
    useEffect(() => {
        setNavbarOptions(dashboardNavbarOptions);
    }, [setNavbarOptions]);

    return (
        <Box sx={{ p: 3 }}>
            <NFTDashboard />
        </Box>
    );
};

export default NFTPage; 