import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

export const useNFTData = (contractAddress = 'overall', days = 30) => {
    const [data, setData] = useState(null);
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { token } = useAuth();

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const response = await axios.get(`${API_URL}/nft/collections`);
                setCollections(response.data);
            } catch (err) {
                console.error('Error fetching NFT collections:', err);
                setError(err.response?.data?.detail || 'Failed to fetch NFT collections');
            }
        };

        const fetchPrices = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/nft/prices/${contractAddress}?days=${days}`
                );
                
                // Transform data for chart
                const chartData = response.data.prices.map(price => ({
                    date: new Date(price.day).toLocaleDateString(),
                    price: price.avg_price
                }));

                setData({
                    contractAddress: response.data.contract_address,
                    collectionName: response.data.collection_name,
                    prices: chartData
                });
            } catch (err) {
                console.error('Error fetching NFT prices:', err);
                setError(err.response?.data?.detail || 'Failed to fetch NFT price data');
            }
        };

        const fetchData = async () => {
            setLoading(true);
            setError(null);
            
            try {
                await Promise.all([fetchCollections(), fetchPrices()]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, [contractAddress, days]);

    return { data, collections, loading, error };
}; 