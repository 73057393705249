import React, { useState } from 'react';
import { Typography, Box, TextField, Select, MenuItem, Button, Chip, FormControl, InputLabel, OutlinedInput } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const datasetOptions = [
  { name: 'Price', value: 'price', table: 'coinmarketcap_data', feature: 'price' },
  { name: 'Reddit Sentiment Average', value: 'reddit_sentiment', table: 'reddit.crypto_sentiment_interval_averages', feature: 'average', extra_filters: { subreddit: 'overall' } },
  { name: 'Reddit Total Comments', value: 'reddit_comments', table: 'reddit.crypto_sentiment_interval_averages', feature: 'total_comments', extra_filters: { subreddit: 'overall' } },
];

const Datasets = () => {
    const [formData, setFormData] = useState({
        datasets: [],
        symbols: [],
        start_datetime: '',
        end_datetime: '',
    });

    const handleDatasetChange = (event) => {
        const { value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            datasets: value.map(datasetValue => 
                datasetOptions.find(option => option.value === datasetValue)
            )
        }));
    };

    const handleSymbolAdd = (e) => {
        if (e.key === 'Enter' && e.target.value) {
            setFormData(prevData => ({
                ...prevData,
                symbols: [...prevData.symbols, e.target.value.toUpperCase()]
            }));
            e.target.value = '';
        }
    };

    const handleSymbolDelete = (symbolToDelete) => {
        setFormData(prevData => ({
            ...prevData,
            symbols: prevData.symbols.filter(symbol => symbol !== symbolToDelete)
        }));
    };

    const handleDateTimeChange = (name) => (event) => {
        setFormData(prevData => ({ ...prevData, [name]: event.target.value }));
    };

    const handleSubmit = () => {
        console.log(JSON.stringify(formData, null, 2));
        // Here you can send the formData to an API or perform any other action
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4">Datasets</Typography>
            <Box sx={{ mt: 2 }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="dataset-label">Datasets</InputLabel>
                    <Select
                        labelId="dataset-label"
                        multiple
                        value={formData.datasets.map(d => d.value)}
                        onChange={handleDatasetChange}
                        input={<OutlinedInput label="Datasets" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={datasetOptions.find(option => option.value === value).name} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {datasetOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Typography variant="h6">Symbols</Typography>
                <TextField
                    fullWidth
                    placeholder="Enter symbol and press Enter"
                    onKeyPress={handleSymbolAdd}
                    sx={{ mb: 1 }}
                />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                    {formData.symbols.map((symbol) => (
                        <Chip
                            key={symbol}
                            label={symbol}
                            onDelete={() => handleSymbolDelete(symbol)}
                        />
                    ))}
                </Box>

                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <TextField
                        label="Start Datetime"
                        type="datetime-local"
                        value={formData.start_datetime}
                        onChange={handleDateTimeChange('start_datetime')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                    <TextField
                        label="End Datetime"
                        type="datetime-local"
                        value={formData.end_datetime}
                        onChange={handleDateTimeChange('end_datetime')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Box>

                <Button variant="contained" onClick={handleSubmit}>
                    Generate JSON
                </Button>
            </Box>
        </Box>
    );
};

export default Datasets;