import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

function PageViewTracker() {
    const location = useLocation();

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.send({ hitType: "pageview", page: location.pathname });
        }
    }, [location]);

    return null;
}

export default PageViewTracker;